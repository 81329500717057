/*
 * BigFuture Featured Articles
 */

@use 'apricot-bf-variable' as *;
@use '../mixins/breakpoint' as *;

.cb-bf-featured-articles {
  /* Main */
  .cb-bf-featured-articles-main {
    margin-bottom: 32px;

    .cb-bf-featured-articles-main-img {
      height: 386px;
      max-height: 386px;

      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: bottom;
      background-size: cover;
    }

    /* Content Block */
    .cb-bf-featured-articles-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      margin-top: 16px;
      margin-bottom: 16px;
      padding: 46px 40px;

      background-color: $white;
      border: 0;
      border-top: 10px solid;
      border-bottom: 10px solid;

      .cb-bf-featured-articles-title,
      .cb-bf-featured-articles-desc {
        margin-bottom: 16px;
      }

      /* Layout */
      @include media-breakpoint-up('md', $grid-breakpoints) {
        max-width: calc(58.3333% - 12px);
        margin-left: -8.3333% !important;
      }

      /* Fonts */
      @include media-breakpoint-only('xs', $grid-breakpoints) {
        .cb-bf-featured-articles-title {
          margin-bottom: 8px;

          /* 16/22 */
          font-size: 1rem;
          line-height: 1.375em;
        }

        .cb-bf-featured-articles-desc {
          margin-bottom: 8px;

          /* 14/22 */
          font-size: 0.875rem;
          line-height: 1.5714em;
        }
      }

      @include media-breakpoint-only('sm', $grid-breakpoints) {
        max-width: calc(100% - 80px);
        margin: 0 40px;
        margin-top: -80px;
        padding: 28px 48px;

        .cb-bf-featured-articles-title {
          margin-bottom: 10px;

          /* 19/28 */
          font-size: 1.1875rem;
          line-height: 1.4737em;
        }

        .cb-bf-featured-articles-desc {
          margin-bottom: 10px;

          /* 16/24 */
          font-size: 1rem;
          line-height: 1.5em;
        }
      }

      @include media-breakpoint-only('md', $grid-breakpoints) {
        min-height: 260px;
        padding: 32px;

        .cb-bf-featured-articles-title {
          margin-bottom: 12px;

          /* 19/24 */
          font-size: 1.1875rem;
          line-height: 1.2632em;
        }

        .cb-bf-featured-articles-desc {
          margin-bottom: 12px;

          /* 16/24 */
          font-size: 1rem;
          line-height: 1.5em;
        }
      }

      @include media-breakpoint-up('lg', $grid-breakpoints) {
        .cb-bf-featured-articles-title {
          /* 24/32 */
          font-size: 1.5rem;
          line-height: 1.3333em;
        }

        .cb-bf-featured-articles-desc {
          /* 19/28 */
          font-size: 1.1875rem;
          line-height: 1.4737em;
        }
      }
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      margin-bottom: 20px;

      .cb-bf-featured-articles-main-img {
        height: 224px;
        max-height: 224px;
      }

      .cb-bf-featured-articles-content {
        width: calc(100% - 24px);
        max-width: calc(100% - 24px);
        margin: 0 12px;
        padding: 24px;

        border-top: 8px solid;
        border-bottom: 8px solid;
      }
    }

    @include media-breakpoint-only('sm', $grid-breakpoints) {
      margin-bottom: 28px;

      .cb-bf-featured-articles-main-img {
        height: 322px;
        max-height: 322px;
      }
    }

    @include media-breakpoint-only('md', $grid-breakpoints) {
      margin-bottom: 32px;

      .cb-bf-featured-articles-main-img {
        height: 300px;
        max-height: 300px;
      }
    }
  }

  /* Secondary */
  .cb-bf-featured-articles-secondary {
    display: flex;

    img {
      width: 156px;
      height: 156px;
      margin-right: 20px;
    }

    .cb-bf-featured-articles-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .cb-bf-featured-articles-title {
        font-weight: 500;
      }

      .cb-bf-featured-articles-title,
      .cb-bf-featured-articles-desc {
        margin-bottom: 4px;
      }

      a {
        font-weight: 700;
      }
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      img {
        width: 110px;
        height: 110px;
        margin-right: 12px;
      }

      + .cb-bf-featured-articles-secondary {
        margin-top: 20px;
      }

      .cb-bf-featured-articles-title,
      .cb-bf-featured-articles-desc,
      a {
        /* 14/18 */
        font-size: 0.875rem;
        line-height: 1.2857em;
      }
    }

    @include media-breakpoint-only('sm', $grid-breakpoints) {
      img {
        width: 148px;
        height: 148px;
        margin-right: 24px;
      }

      + .cb-bf-featured-articles-secondary {
        margin-top: 28px;
      }
    }

    @include media-breakpoint-only('md', $grid-breakpoints) {
      img {
        width: 118px;
        height: 118px;
        margin-right: 16px;
      }
    }

    @include media-breakpoint-up('sm', $grid-breakpoints) {
      .cb-bf-featured-articles-title {
        /* 16/22 */
        font-size: 1rem;
        line-height: 1.375em;
      }

      .cb-bf-featured-articles-desc,
      a {
        /* 16/24 */
        font-size: 1rem;
        line-height: 1.5em;
      }
    }

    @include media-breakpoint-up('lg', $grid-breakpoints) {
      .cb-bf-featured-articles-title {
        /* 19/28 */
        font-size: 1.1875rem;
        line-height: 1.4737em;
      }

      .cb-bf-featured-articles-desc,
      a {
        /* 16/24 */
        font-size: 1rem;
        line-height: 1.5em;
      }
    }
  }
}
