@use 'sass:math';
@use 'sass:map';

/*
 * BigFuture Variables
 */
 
$illus-file: 'cb-bf-illus-4_7_5';

/* ------------------------------------ BREAKPOINTS */

/* 7 breakpoints */
$grid-breakpoints: (
  xs: 1px,
  sm: 768px,
  md: 1024px,
  lg: 1248px,
  xl: 1344px,
  2xl: 1440px,
  3xl: 1728px,
) !default;
$white: #fff;
$black: #000;
$black1: #1e1e1e;

/* ------------------------------------ BLUE */
$blue: #324dc7;
$blue-shade1: #0036ab;
$blue-shade2: #001075;
$blue-tint2: #edf0f9;
$blue-tint1: #cedbf3;

/* ------------------------------------ ORANGE */
$orange: #fe5b00;
$orange-shade1: #e55200;
$orange-shade2: #cf4b00;
$orange-tint2: #ffefe6;
$orange-tint1: #ffbd99;

/* ------------------------------------ CHARTREUSE */
$chartreuse: #e1f400;
$chartreuse-shade1: #cbdc00;
$chartreuse-shade2: #a3c200;
$chartreuse-tint2: #fcfee6;
$chartreuse-tint1: #f3fb99;

/* ------------------------------------ PINK */
$pink: #fc427b;
$pink-shade1: #e33b6f;
$pink-shade2: #ba2956;
$pink-tint2: #ffecf2;
$pink-tint1: #fec6d7;

/* ------------------------------------ NATURAL */
$neutral: #f9f5f2;
$neutral-shade1: #f1e5db;
$neutral-shade2: #faf3ed;

/* ------------------------------------ COLORS */

$color-types: () !default;
$color-types: (
  'bg': background-color,
  'color': color,
  'border': border-color,
);
$color-themes: () !default;
$color-themes: map.merge(
  (
    blue: (
      bg: $blue,
      shade-1: $blue-shade1,
      shade-2: $blue-shade2,
      tint-1: $blue-tint1,
      tint-2: $blue-tint2,
    ),
    orange: (
      bg: $orange,
      shade-1: $orange-shade1,
      shade-2: $orange-shade2,
      tint-1: $orange-tint1,
      tint-2: $orange-tint2,
    ),
    chartreuse: (
      bg: $chartreuse,
      shade-1: $chartreuse-shade1,
      shade-2: $chartreuse-shade2,
      tint-1: $chartreuse-tint1,
      tint-2: $chartreuse-tint2,
    ),
    pink: (
      bg: $pink,
      shade-1: $pink-shade1,
      shade-2: $pink-shade2,
      tint-1: $pink-tint1,
      tint-2: $pink-tint2,
    ),
    neutral: (
      bg: $neutral,
      shade-1: $neutral-shade1,
      shade-2: $neutral-shade2,
    ),
  ),
  $color-themes
);

/* ------------------------------------ HIGHLIGHT */
$highlight: $orange-tint1;
$highlight-orange: $orange-tint2;
$highlight-blue: $blue-tint1;

/* ------------------------------------ LINK */
$link: $blue;

/* ------------------------------------ FONTS */
$font-path: '../../fonts/';

/* Headings */

/* XS (mobile first) values */
$heading: (
  h1: (
    /* 28 */ size: 1.75rem,
    /* 36 */ line: 1.2857em,
  ),
  h2: (
    /* 20 */ size: 1.25rem,
    /* 28 */ line: 1.4em,
  ),
  h3: (
    /* 18 */ size: 1.125rem,
    /* 24 */ line: 1.3333em,
  ),
  h4: (
    /* 16 */ size: 1rem,
    /* 22 */ line: 1.375em,
  ),
  h5: (
    /* 14 */ size: 0.875rem,
    /* 20 */ line: 1.4286em,
  ),
  h6: (
    /* 12 */ size: 0.75rem,
    /* 18 */ line: 1.5em,
  ),
);

/* ------------------------------------ ILLUSTRATIONS */

$illus-size: (64, 72, 96, 120);
$illus: () !default;
$illus: map.merge(
  (
    'acorn': (
      'before': e93a,
      'after': e9d5,
    ),
    'careers': (
      'before': e951,
      'after': e9d6,
    ),
    'celebrate': (
      'before': e99e,
      'after': e9d7,
    ),
    'exclamation': (
      'before': e9ca,
      'after': e9d9,
    ),
    'guidance': (
      'before': e9cb,
      'after': e9da,
    ),
    'high-five': (
      'before': e9cc,
      'after': e9db,
    ),
    'journey': (
      'before': e9cd,
      'after': e9dc,
    ),
    'math': (
      'before': e9ce,
      'after': e9dd,
    ),
    'quiz': (
      'before': e9d2,
      'after': e9e1,
    ),
    'social-youtube': (
      'before': e9d4,
      'after': e9e3,
    ),
    'accessibility': (
      'before': e900,
      'after': e965,
    ),
    'apple': (
      'before': e901,
      'after': e966,
    ),
    'award-trophy': (
      'before': e902,
      'after': e967,
    ),
    'award': (
      'before': e903,
      'after': e968,
    ),
    'bar-graph': (
      'before': e904,
      'after': e969,
    ),
    'bell': (
      'before': e905,
      'after': e96a,
    ),
    'book-index': (
      'before': e906,
      'after': e96b,
    ),
    'book-open': (
      'before': e907,
      'after': e96c,
    ),
    'browser-window-generic': (
      'before': e908,
      'after': e96d,
    ),
    'bullet-list-device': (
      'before': e909,
      'after': e96e,
    ),
    'bullet-list-doc': (
      'before': e90a,
      'after': e96f,
    ),
    'calendar-add': (
      'before': e90b,
      'after': e970,
    ),
    'calendar-empty': (
      'before': e90c,
      'after': e971,
    ),
    'calendar-full': (
      'before': e90d,
      'after': e972,
    ),
    'calendar-save-date': (
      'before': e90e,
      'after': e973,
    ),
    'calendar': (
      'before': e90f,
      'after': e974,
    ),
    'cart': (
      'before': e910,
      'after': e975,
    ),
    'chat': (
      'before': e911,
      'after': e976,
    ),
    'checklist-device-complete': (
      'before': e912,
      'after': e977,
    ),
    'checklist-device': (
      'before': e913,
      'after': e978,
    ),
    'checklist-doc': (
      'before': e914,
      'after': e979,
    ),
    'check-mark': (
      'before': e9b5,
      'after': e9d8,
    ),
    'city-school': (
      'before': e916,
      'after': e97b,
    ),
    'clock': (
      'before': e917,
      'after': e97c,
    ),
    'coin': (
      'before': e918,
      'after': e97d,
    ),
    'compose': (
      'before': e919,
      'after': e9c9,
    ),
    'computer': (
      'before': e91a,
      'after': e97e,
    ),
    'device-tablet': (
      'before': e91b,
      'after': e97f,
    ),
    'document': (
      'before': e91c,
      'after': e980,
    ),
    'dollar': (
      'before': e91d,
      'after': e981,
    ),
    'download': (
      'before': e91e,
      'after': e982,
    ),
    'education-for-all': (
      'before': e91f,
      'after': e983,
    ),
    'error': (
      'before': e920,
      'after': e984,
    ),
    'expand-window': (
      'before': e921,
      'after': e985,
    ),
    'explore': (
      'before': e922,
      'after': e986,
    ),
    'face-excellent': (
      'before': e923,
      'after': e987,
    ),
    'face-frown': (
      'before': e924,
      'after': e988,
    ),
    'face-good': (
      'before': e925,
      'after': e989,
    ),
    'face-happy': (
      'before': e926,
      'after': e98a,
    ),
    'face-neutral': (
      'before': e927,
      'after': e98b,
    ),
    'face-very-bad': (
      'before': e928,
      'after': e98c,
    ),
    'face-worried': (
      'before': e929,
      'after': e98d,
    ),
    'social-facebook': (
      'before': e92a,
      'after': e98e,
    ),
    'feedback': (
      'before': e92b,
      'after': e98f,
    ),
    'generic-clipboard': (
      'before': e92c,
      'after': e990,
    ),
    'globe': (
      'before': e92d,
      'after': e991,
    ),
    'graduate': (
      'before': e92e,
      'after': e992,
    ),
    'home': (
      'before': e92f,
      'after': e993,
    ),
    'info': (
      'before': e930,
      'after': e994,
    ),
    'information': (
      'before': e931,
      'after': e995,
    ),
    'social-instagram': (
      'before': e932,
      'after': e996,
    ),
    'kahn-academy': (
      'before': e933,
      'after': e997,
    ),
    'laptop-exclamation': (
      'before': e934,
      'after': e998,
    ),
    'laptop': (
      'before': e935,
      'after': e999,
    ),
    'lightbulb': (
      'before': e936,
      'after': e99a,
    ),
    'social-linkedin': (
      'before': e937,
      'after': e99b,
    ),
    'location': (
      'before': e938,
      'after': e99c,
    ),
    'lock': (
      'before': e939,
      'after': e99d,
    ),
    'medal': (
      'before': e93b,
      'after': e99f,
    ),
    'megaphone': (
      'before': e93c,
      'after': e9a0,
    ),
    'new-page': (
      'before': e93d,
      'after': e9a1,
    ),
    'note': (
      'before': e93e,
      'after': e9a2,
    ),
    'notepad-check': (
      'before': e93f,
      'after': e9a3,
    ),
    'open-new-window': (
      'before': e940,
      'after': e9a4,
    ),
    'opened': (
      'before': e941,
      'after': e9a5,
    ),
    'pages': (
      'before': e942,
      'after': e9a6,
    ),
    'paper-airplane': (
      'before': e943,
      'after': e9a7,
    ),
    'pencil': (
      'before': e944,
      'after': e9a8,
    ),
    'phone': (
      'before': e945,
      'after': e9a9,
    ),
    'pillar-explore': (
      'before': e9cf,
      'after': e9de,
    ),
    'pillar-pay': (
      'before': e9d0,
      'after': e9df,
    ),
    'pillar-plan': (
      'before': e9d1,
      'after': e9e0,
    ),
    'play': (
      'before': e949,
      'after': e9ad,
    ),
    'practice-test': (
      'before': e94a,
      'after': e9ae,
    ),
    'practice': (
      'before': e94b,
      'after': e9af,
    ),
    'print': (
      'before': e94c,
      'after': e9b0,
    ),
    'question': (
      'before': e94d,
      'after': e9b1,
    ),
    'quotes': (
      'before': e94e,
      'after': e9b2,
    ),
    'school-sparkle': (
      'before': e94f,
      'after': e9b3,
    ),
    'school': (
      'before': e950,
      'after': e9b4,
    ),
    'science-microscope': (
      'before': e952,
      'after': e9b6,
    ),
    'search': (
      'before': e953,
      'after': e9b7,
    ),
    'send-scores': (
      'before': e954,
      'after': e9b8,
    ),
    'send': (
      'before': e955,
      'after': e9b9,
    ),
    'settings': (
      'before': e956,
      'after': e9ba,
    ),
    'share': (
      'before': e957,
      'after': e9bb,
    ),
    'stopwatch': (
      'before': e958,
      'after': e9bc,
    ),
    'submit-photo': (
      'before': e959,
      'after': e9bd,
    ),
    'submit': (
      'before': e95a,
      'after': e9be,
    ),
    'task-complete': (
      'before': e95b,
      'after': e9bf,
    ),
    'team-leader': (
      'before': e95c,
      'after': e9c0,
    ),
    'social-tiktok': (
      'before': e9d3,
      'after': e9e2,
    ),
    'social-twitter': (
      'before': e95e,
      'after': e9c2,
    ),
    'university': (
      'before': e95f,
      'after': e9c3,
    ),
    'unlock': (
      'before': e960,
      'after': e9c4,
    ),
    'upload': (
      'before': e961,
      'after': e9c5,
    ),
    'user-add': (
      'before': e962,
      'after': e9c6,
    ),
    'user': (
      'before': e963,
      'after': e9c7,
    ),
    'write-chat': (
      'before': e964,
      'after': e9c8,
    ),
  ),
  $illus
);

/* ------------------------------------ TRANSITION */

$transition-time-s: 0.25s;
$transition-time: 0.3s;
$transition-time-l: 0.35s;
